import React from 'react'
import Calendar from '@ericz1803/react-google-calendar'

const API_KEY = 'AIzaSyAg9FsWFKgdWkEYPWiFWW6XFABqOYX343k'
const calendars = [
  { calendarId: '55da51025940eb8f3dd1e51ca4f5dba0b305c0af0a64c0ade5e68a73f831104b@group.calendar.google.com' }
]

const BashCalendar = () => {
  return (
      <div>
        <Calendar apiKey={API_KEY} calendars={calendars} />
      </div>
  )
}

export default BashCalendar
