import React from 'react'
// import sections
import Events1Hero from '../components/sections/Events/Events1Hero'
import Events2CalendarSplit from '../components/sections/Events/Events2CalendarSplit'
// import EventsBurgers from '../components/sections/Events/EventsBurgers'
// import EventsFries from '../components/sections/Events/EventsFries'
// import EventsSnacks from '../components/sections/Events/EventsSnacks'

const Events = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  return (
    <>
      <Events1Hero className="illustration-section-01" />
      <Events2CalendarSplit />
    </>
  )
}

export default Events
