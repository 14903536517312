/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
import React from 'react'
import classNames from 'classnames'
import { SectionSplitProps } from '../../../utils/SectionProps'
import SectionHeader from '../partials/SectionHeader'
import Image from '../../elements/Image'

// import Calendar from '@ericz1803/react-google-calendar'
import BashCalendar from '../../elements/BashCalendar'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const Events2CalendarSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  )

  const sectionHeader = {
    title: 'Impressing friends is easy, let us handle it',
    paragraph: 'Whether it\'s for a meeting or party, our fun and delicous platters are sure to be the feature highlight.'
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Bookings Calendar:
                  </div>
                  <h3 className="mt-0 mb-12">
                  Check Availability
                  </h3>
                <p className="m-0">
                Scope out our live calendar for where and when to find us. Feel free to jab us for available dates.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                {/* <Calendar apiKey={API_KEY} calendars={calendars} /> */}
                <BashCalendar
                  alt="Features split 01"
                  width={528}
                  height={396}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Events2CalendarSplit.propTypes = propTypes
Events2CalendarSplit.defaultProps = defaultProps

export default Events2CalendarSplit
