/* eslint-disable react/no-children-prop */
import React, { useRef, useEffect } from 'react'
import { useLocation, Routes, Route } from 'react-router-dom'

// import AppRoute from './utils/AppRoute'
import ScrollReveal from './utils/ScrollReveal'
import ReactGA from 'react-ga'

// Layouts
import LayoutDefault from './layouts/LayoutDefault'

// Views
import Home from './views/Home'
import Events from './views/Events'
import Menu from './views/Menu'
import Locations from './views/Locations'
import Contact from './views/Contact'

// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);
ReactGA.initialize('G-XRJ69W0NW1')

const trackPage = (page) => {
  ReactGA.set({ page })
  ReactGA.pageview(page)
}

const App = () => {
  const childRef = useRef()
  const location = useLocation()

  useEffect(() => {
    const page = location.pathname
    document.body.classList.add('is-loaded')
    childRef.current.init()
    trackPage(page)
  }, [location])

  return (

    <ScrollReveal
      ref={childRef}
      children={() => (
        <Routes>
            <Route exact path="/" element={<Home />} layout={<LayoutDefault />}></Route>
            <Route exact path="/events" element={<Events />} layout={<LayoutDefault />}></Route>
            <Route exact path="/menu" element={<Menu />} layout={<LayoutDefault />}></Route>
            <Route exact path="/locations" element={<Locations />} layout={<LayoutDefault />}></Route>
            <Route exact path="/contact" element={<Contact />} layout={<LayoutDefault />}></Route>
        </Routes>
      )} />

  )
}

export default App
