import React from 'react'
import classNames from 'classnames'
import { SectionProps } from '../../utils/SectionProps'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const LocationsHero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const [] = useState(false)

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
              Contact <br/><span className="text-color-primary">Bash Food Truck</span>
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">
              Truckin&apos; Good Food
                </p>
              {/* <div className="reveal-from-bottom" data-reveal-delay="600">
              <Image
                className="has-shadow"
                src={require('./../../assets/images/google-reviews.png')}
                alt="Google"
                width={200}
                height={100} />
                <ButtonGroup>
                  <Button tag="a" color="primary" wideMobile href="https://bit.ly/3DSpgQJ">
                    See Reviews
                    </Button>
                  <Button tag="a" color="dark" wideMobile href="https://g.page/r/CTn8-qqV-HhSEAg/review">
                    Write Reviews
                    </Button>
                </ButtonGroup>
              </div> */}
            </div>
          </div>
          {/* <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="800">
            <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/hero-truck.jpg')}
                alt="Hero"
                width={896}
                height={504} />
            </a>
          </div> */}
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe" /> */}
        </div>
      </div>
    </section>
  )
}

LocationsHero.propTypes = propTypes
LocationsHero.defaultProps = defaultProps

export default LocationsHero
