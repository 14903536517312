/* eslint-disable no-unused-vars */
import React from 'react'
// import sections
import Hero from '../components/sections/Hero'
import FeaturesTiles from '../components/sections/FeaturesTiles'
import HeroReviews from '../components/sections/HeroReviews'

const Home = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <HeroReviews />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Testimonial topDivider />
      <Cta split /> */}
    </>
  )
}

export default Home
