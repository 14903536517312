/* eslint-disable no-unused-vars */
import React from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../utils/SectionProps'
import SectionHeader from './partials/SectionHeader'
import Image from '../elements/Image'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const sectionHeader = {
    title: 'The ultimate food truck experience!',
    paragraph: 'The premier food truck for delicious Specialty Wraps, mouthwaterin Poutines and Treats made on-premises! Our signature recipes use only fresh Canadian Chicken and Beef that is seasoned and cooked to perfection without any fillers or preservatives. We also offer hand-cut French fries and poutine made with locally sourced Canadian potatoes.'
  }

  const sectionHeader2 = {
    paragraph: 'As a small, family-owned and operated business, we pride ourselves on our commitment to quality ingredients and chef-inspired recipes. We also strive to offer the best customer service possible, ensuring that every customer leaves satisfied.'
  }

  const sectionHeader3 = {
    paragraph: 'During office hours, we cater to the professional clientele in the area, providing a convenient and tasty lunch option. We also love being a part of festivals and private events, bringing our delicious food to a wider audience. If you\'re in the mood for delicious wraps and more, come visit us and taste the difference for yourself!'
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          {/* <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/southwest-wrap.png')}
                      alt="Features tile icon 01"
                      width={200}
                      height={126} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Southwest Wrap
                    </h4>
                  <p className="m-0 text-sm">
                    Our best-selling wrap. Housemade crispy chicken, smokey bacon and shredded St.Albert Cheddar Cheese.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/texan-wrap.png')}
                      alt="Features tile icon 02"
                      width={200}
                      height={134} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Texan Wrap
                    </h4>
                  <p className="m-0 text-sm">
                    Fresh Canadian Beef. No Filler. No Preservatives. Smoked Bacon. St Albert Cheddar Cheese. Hickory BBQ Sauce.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/dynamite-wrap.png')}
                      alt="Features tile icon 03"
                      width={200}
                      height={102} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Dynamite Wrap
                    </h4>
                  <p className="m-0 text-sm">
                    Crispy Chicken. Cucumber Radish Salad. Garlic Parm Sauce. Sweet Thai Sauce.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/honey-garlic-wrap.png')}
                      alt="Features tile icon 04"
                      width={200}
                      height={135} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Honey Garlic Wrap
                    </h4>
                  <p className="m-0 text-sm">
                    Crispy Chicken. Smoked Bacon. Cheddar Cheese. Honey Garlic Sauce.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/sweet-onion-wrap.png')}
                      alt="Features tile icon 05"
                      width={200}
                      height={110} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Sweet Onion Wrap
                    </h4>
                  <p className="m-0 text-sm">
                    Fresh Canadian Beef. No Filler. No Preservatives. Coleslaw. Crispy Onions. Sweet Onion Sauce.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/sweet-heat-wrap.png')}
                      alt="Features tile icon 06"
                      width={200}
                      height={126} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Sweet Heat Wrap
                    </h4>
                  <p className="m-0 text-sm">
                    Crispy Chicken. Coleslaw. Crispy Jalapenos. Sweet and Spicy Sauce.
                    </p>
                </div>
              </div>
            </div>

          </div> */}
          <SectionHeader data={sectionHeader2} className="center-content" />
          {
          }
          <SectionHeader data={sectionHeader3} className="center-content" />
          {
          }
        </div>
      </div>
    </section>
  )
}

FeaturesTiles.propTypes = propTypes
FeaturesTiles.defaultProps = defaultProps

export default FeaturesTiles
