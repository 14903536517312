/* eslint-disable no-unused-vars */
import React from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../../utils/SectionProps'
import SectionHeader from '../partials/SectionHeader'
import Image from '../../elements/Image'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const MenuBurgers = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  )

  const burgersHeader = {
    title: 'High Octane Burgers',
    paragraph: 'Self-proclaimed "Best Burgers in Town".'
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        {/* START - Burgers */}
<div className={innerClasses}>
            <SectionHeader data={burgersHeader} className="center-content" />
            <div className={tilesClasses}>
{/* Smokey Jack */}
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Burger---Smokey-Jack.png')}
                        alt="Features tile icon 04"
                        width={300}
                        height={229} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Smokey Jack
                    </h4>
                    <p className="m-0 text-sm">
                    Fresh Canadian Beef. No Filler. No Preservatives. Stacked with Crispy Onion Rings, Melted Mozza Cheese and Hickory BBQ Sauce.
                    </p>
                  </div>
                </div>
              </div>
{/* Chipotle Burger */}
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Burger---Chipotle.png')}
                        alt="Features tile icon 05"
                        width={300}
                        height={229} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Chipotle Burger
                    </h4>
                    <p className="m-0 text-sm">
                    Fresh Canadian Beef. No Filler. No Preservatives. Stacked with Crispy Onion Rings, Melted Mozza Cheese, Jalapeno Peppers and Chipotle Sauce.
                    </p>
                  </div>

                </div>
              </div>
{/* Big Texan */}
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Burger---Big-Texan.png')}
                        alt="Features tile icon 05"
                        width={300}
                        height={229} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Big Texan
                    </h4>
                    <p className="m-0 text-sm">
                    Fresh Canadian Beef. No Filler. No Preservatives. Stacked with Crispy Smokey Bacon, Melted Cheddar Cheese, Sauteed Mushrooms and Onions, and Hickory BBQ Sauce.
                    </p>
                  </div>

                </div>
              </div>

{/* Peppercorn Ranch */}
          <div className="tiles-item reveal-from-bottom">
            <div className="tiles-item-inner">
              <div className="features-tiles-item-header">
                <div className="features-tiles-item-image mb-16">
                  <Image
                    src={require('./../../../assets/images/PeppercornRanch.png')}
                    alt="Features tile icon 04"
                    width={300}
                    height={150} />
                </div>
              </div>
              <div className="features-tiles-item-content">
                <h4 className="mt-0 mb-8">
                  Peppercorn Ranch
                </h4>
                <p className="m-0 text-sm">
                Fresh Canadian Beef. No Filler. No Preservatives. Stacked with Crisp Bacon, Crispy Onion Rings, Melted Cheddar Cheese, and Peppercorn Ranch Sauce
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
{/* END - Burgers */}
      </div>
    </section>

  )
}

MenuBurgers.propTypes = propTypes
MenuBurgers.defaultProps = defaultProps

export default MenuBurgers
