import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'

const FacebookChat = () => {
  return (
    <div>
      {/* Your existing React components */}
      <MessengerCustomerChat
        pageId="213066318552356"
        appId="yyzanalyticscustomerservice"
        // htmlRef="<REF-STRING>" // Optional: Add a unique identifier for the Messenger chat plugin
      />
    </div>
  )
}

export default FacebookChat
