import React from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../../utils/SectionProps'
import SectionHeader from '../partials/SectionHeader'
import Image from '../../elements/Image'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const MenuFries = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  )

  const poutinesHeader = {
    title: 'Riding the Gravy Train',
    paragraph: 'Gets your motor running.'
  }

  const sidesHeader = {
    title: 'Trusted Companions',
    paragraph: 'Great sides or enjoy them as a meal on their own.'
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
{/* START - Poutines */}
<div className={innerClasses}>
            <SectionHeader data={poutinesHeader} className="center-content" />
            <div className={tilesClasses}>

{/* BEGINNING OF FIRST ROW */}
{/* BUFFALO CHICKEN POUTINE */}
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Buffalo-Chicken-Poutine.png')}
                        alt="Features tile icon 04"
                        width={300}
                        height={188} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Buffalo Chicken Poutine
                    </h4>
                    <p className="m-0 text-sm">
                      Hand-cut. Twice Fried. Crispy French Fries. Crisy Chicken. Buffalo Sauce. Peppercorn Ranch Sauce. 5 Star gravy. St Albert Cheese Curds.
                    </p>
                  </div>
                </div>
              </div>
{/* HOT HONEY POUTINE */}
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Hot-Honey-Poutine.png')}
                        alt="Features tile icon 05"
                        width={300}
                        height={188} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Hot Honey Poutine
                    </h4>
                    <p className="m-0 text-sm">
                    Hand-cut. Twice Fried. Crispy French Fries. Crispy Chicken. Honey Garlic Sauce. Frank&apos;s Hot Sauce. 5 Star gravy. St Albert Cheese Curds.
                    </p>
                  </div>

                </div>
              </div>
{/* KICKIN CHICKEN POUTINE */}
              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Kickin-Chicken-Poutine.png')}
                        alt="Features tile icon 05"
                        width={300}
                        height={188} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                    Kickin&apos; Chicken Poutine
                    </h4>
                    <p className="m-0 text-sm">
                    Hand-cut. Twice Fried. Crispy French Fries. Roasted Chicken. Diced Jalapenos. Signature Bash Sauce. 5 Star gravy. St Albert Cheese Curds.
                    </p>
                  </div>

                </div>
              </div>
{/* END OF FIRST ROW */}

{/* BEGINNING OF SECOND ROW */}
{/* POGO POUTINE */}
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Pogo-Poutine.png')}
                      alt="Features tile icon 04"
                      width={300}
                      height={188} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Pogo Poutine
                  </h4>
                  <p className="m-0 text-sm">
                    An American classic. Hand-cut. Twice Fried. Crispy French Fries. Fried Corn Dog. 5 Star gravy. St Albert Cheese Curds.
                  </p>
                </div>
              </div>
            </div>
{/* THE BASH PROJECT POUTINE */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/The-Bash-Project-Poutine.png')}
                      alt="Features tile icon 05"
                      width={300}
                      height={188} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    The Bash Project Poutine
                  </h4>
                  <p className="m-0 text-sm">
                  Hand-cut. Twice Fried. Crispy French Fries. Maple Smoked Bacon. Spicy Chorizo Sausage. Prime Seasoned Beef. Signature Bash Sauce. 5 Star gravy. St Albert Cheese Curds.
                  </p>
                </div>
              </div>
            </div>
{/* HOG HEAVEN POUTINE */}
            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../../assets/images/Hog-Heaven-Poutine.png')}
                      alt="Features tile icon 06"
                      width={300}
                      height={188} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Hog Heaven Poutine
                  </h4>
                  <p className="m-0 text-sm">
                  Hand-cut. Twice Fried. Crispy French Fries. Maple Smoked Bacon. Spicy Chorizo Sausage. 5 Star gravy. St Albert Cheese Curds.
                  </p>
                </div>
              </div>
            </div>
{/* END OF SECOND ROW */}

{/* BEGINNING OF THIRD ROW */}
{/* GRILL MASTERS BEEF POUTINE */}
              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Grill-Masters-Beef-Poutine.png')}
                        alt="Features tile icon 04"
                        width={300}
                        height={188} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Grill Master&apos;s Beef Poutine
                    </h4>
                    <p className="m-0 text-sm">
                      Hand-cut. Twice Fried. Crispy French Fries. Canadian Grilled Beef. Sauteed Mushrooms and Onions. Signature Bash Sauce. 5 Star gravy. St Albert Cheese Curds.
                    </p>
                  </div>
                </div>
              </div>
{/* CHICKEN BACON RANCHER */}
<div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/Chicken-Bacon-Rancher.png')}
                        alt="Features tile icon 04"
                        width={300}
                        height={188} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Chicken Bacon Rancher Poutine
                    </h4>
                    <p className="m-0 text-sm">
                      Seasoned Roasted Chicken, Crispy Bacon, Cruds, Gravy, Fry Seasoning, Peppercorn Ranch Sauce
                    </p>
                  </div>
                </div>
              </div>
{/* END OF THIRD ROW */}
            </div>
          </div>

{/* END - Poutine           */}

{/* START - Fries */}
          <div className={innerClasses}>
            <SectionHeader data={sidesHeader} className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/cajun-fries-icon.png')}
                        alt="Features tile icon 04"
                        width={300}
                        height={188} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Cajun French Fries
                    </h4>
                    <p className="m-0 text-sm">
                      Hand-cut. Twice Fried. Crispy French Fries. Cajun Paprika Seasoning Spice. Chipotle Mayo Sauce.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={require('./../../../assets/images/garlic-parm-fries-icon.png')}
                        alt="Features tile icon 05"
                        width={300}
                        height={188} />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Garlic Parm French Fries
                    </h4>
                    <p className="m-0 text-sm">
                      Hand-cut. Twice Fried. Crispy French Fries. Herb and Garlic Seasoning. Real Parmesan Cheese. Garlic Parm Dipping Sauce.
                    </p>
                  </div>
                </div>
              </div>
            </div>
{/* END - Fries & Poutine */}

          </div>

      </div>
    </section>

  )
}

MenuFries.propTypes = propTypes
MenuFries.defaultProps = defaultProps

export default MenuFries
