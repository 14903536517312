import React from 'react'
import classNames from 'classnames'
import { SectionProps } from '../../utils/SectionProps'
import ButtonGroup from '../elements/ButtonGroup'
import Button from '../elements/Button'
import Image from '../elements/Image'

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const HeroReviews = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const [] = useState(false)

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  )

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
            <div className="reveal-from-bottom" data-reveal-delay="600">
                <Image
                    className="has-shadow"
                    src={require('./../../assets/images/google-reviews.png')}
                    alt="Google"
                    width={200}
                    height={100} />
                    <ButtonGroup>
                    <Button tag="a" color="primary" wideMobile href="https://bit.ly/3DSpgQJ">
                        See Reviews
                        </Button>
                    <Button tag="a" color="dark" wideMobile href="https://g.page/r/CTn8-qqV-HhSEAg/review">
                        Write Reviews
                        </Button>
                    </ButtonGroup>
            </div>
        </div>
      </div>
    </section>
  )
}

HeroReviews.propTypes = propTypes
HeroReviews.defaultProps = defaultProps

export default HeroReviews
