import React from 'react'
// import sections
import LocationsHero from '../components/sections/LocationsHero.js'
import LocationsFeaturesSplit from '../components/sections/LocationsFeaturesSplit'

const Locations = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })

  return (
    <>
      <LocationsHero className="illustration-section-01" />
      {/* <FeaturesTiles /> */}
      <LocationsFeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* <Testimonial topDivider />
      <Cta split /> */}
    </>
  )
}

export default Locations
